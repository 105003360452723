import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAddressCoverage } from "../../../contexts/address-coverage-demo.context";
import config from "../../../utils/config";
import { CloseButton } from "./components/CloseButton";
import classNames from "classnames";
import { Address, AddressInputProps, AddressInputRef } from "./contracts";
import { PlacesSearchResults } from "./components/PlacesSearchResults";
import { Loading } from "./components/Loading";
import { mapPlaceToAddress } from "./services";
import { Modal } from "./components/Modal";
import { Info } from "lucide-react";
import Informations from "./components/Informations";
import validatePlace from "./services/validatePlace";
import dayjs from "../../../utils/dayjs";

const Component = forwardRef<AddressInputRef, AddressInputProps>(
  (
    {
      value,
      label,
      onAddressChange,
      onPlaceChange,
      setAddressError,
      clearAddressError,
    }: AddressInputProps,
    ref
  ) => {
    const {
      placesService,
      getPlacePredictions,
      placePredictions,
      isPlacePredictionsLoading,
    } = usePlacesService({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      options: {
        input: value || "",
        types: ["address"],
        componentRestrictions: { country: "fr" },
      },
    });

    const { validateInterruption, setAddressPlace, externalPlace } =
      useAddressCoverage();

    const searchInputRef = useRef<HTMLInputElement>(null);

    const [currentValue, setCurrentValue] = useState(value || "");
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [externalInputChanged, setExternalInputChanged] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [exportableAddress, setExportableAddress] = useState<Address | null>(
      null
    );

    const [displayUserNotes, setDisplayUserNotes] = useState(false);
    const [hasAddressChanged, setHasAddressChanged] = useState(false);
    const [selectedPlace, setSelectedPlace] =
      useState<google.maps.places.PlaceResult | null>(null);

    const onClearHandler = useCallback(() => {
      getPlacePredictions({ input: "" });
      setCurrentValue("");
      onAddressChange?.(null);
      clearAddressError && clearAddressError();
      searchInputRef.current?.focus();
    }, [clearAddressError, getPlacePredictions, onAddressChange]);

    const onInputChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        getPlacePredictions({ input: event.target.value });
      },
      [getPlacePredictions]
    );

    const handlePlaceDetails = useCallback(
      async (
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place !== undefined &&
          place !== null
        ) {
          const placeValidationResult = validatePlace(place);

          if (placeValidationResult) {
            setAddressError && setAddressError(placeValidationResult);
            return;
          }

          const address = mapPlaceToAddress(place);

          setIsEditMode(false);
          setSelectedPlace(place);

          try {
            setExportableAddress(address);
          } catch (e) {
            setAddressError &&
              setAddressError(
                "Une erreur est survenue lors de la récupération de l'adresse, veuillez réessayer."
              );
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [validateInterruption]
    );

    const refreshPlaceDetails = useCallback(
      (placeId: string) => {
        if (placesService) {
          placesService.getDetails(
            {
              placeId: placeId,
            },
            handlePlaceDetails
          );
        }
      },
      [handlePlaceDetails, placesService]
    );

    const onSelect = useCallback(
      (place: google.maps.places.AutocompletePrediction) => {
        setHasAddressChanged(true);
        clearAddressError && clearAddressError();

        setCurrentValue(place.description);
        getPlacePredictions({ input: place.description });
        setIsInputFocused(false);
        refreshPlaceDetails(place.place_id);
      },
      [clearAddressError, getPlacePredictions, refreshPlaceDetails]
    );

    const onInputFocused = useCallback(() => {
      setExternalInputChanged(false);
      setIsInputFocused(true);
    }, []);

    const onInputBlurred = useCallback(() => {
      setIsInputFocused(false);
    }, []);

    const onEnterEditMode = useCallback(() => {
      setIsEditMode(true);
    }, [setIsEditMode]);

    useImperativeHandle(ref, () => {
      return () => searchInputRef?.current?.focus();
    });

    useEffect(() => {
      if (isEditMode) {
        searchInputRef.current?.focus();
      }
    }, [isEditMode, searchInputRef]);

    useEffect(() => {
      if (!placesService) {
        return;
      }
    }, [placesService]);

    useEffect(() => {
      (async () => {
        setIsEditMode(true);
        setCurrentValue(value || "");
        setExternalInputChanged(true);
        if (value) {
          getPlacePredictions({ input: value });
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]); // Do not add getPlacePredictions to the dependencies, it will cause an infinite loop

    useEffect(() => {
      if (onAddressChange)
        (async () => {
          selectedPlace && setAddressPlace(selectedPlace);
          onPlaceChange?.(selectedPlace);
          onAddressChange?.(exportableAddress);
        })();
    }, [
      exportableAddress,
      selectedPlace,
      onAddressChange,
      onPlaceChange,
      setAddressPlace,
      hasAddressChanged,
    ]);

    useEffect(() => {
      (async () => {
        if (externalPlace === null || externalPlace === undefined) return;
        setIsInputFocused(false);

        if (externalPlace?.place_id !== undefined)
          refreshPlaceDetails(externalPlace.place_id);

        if (externalPlace?.formatted_address) {
          setCurrentValue(externalPlace.formatted_address);
        } else if (
          externalPlace?.address_components &&
          externalPlace?.address_components.length >= 7
        ) {
          console.warn(
            "🚀 ~ externalPlace?.else:",
            externalPlace?.address_components &&
              externalPlace?.address_components.length >= 7
          );
          const address = mapPlaceToAddress(externalPlace);
          setCurrentValue(address.formatted_address);
        }
      })();
    }, [externalPlace, refreshPlaceDetails]);

    return (
      <>
        <section
          className="relative w-full flex items-center gap-1 text-[#05bca4] font-bold cursor-pointer"
          onClick={() => {
            setDisplayUserNotes(!displayUserNotes);
          }}
        >
          <span className="text-[0.7rem]">{label}</span> <Info />
        </section>

        {displayUserNotes && (
          <Modal
            title="Comment ça marche ?"
            closeable={true}
            onClose={() => setDisplayUserNotes(false)}
          >
            <Informations />
          </Modal>
        )}
        {selectedPlace !== null && !isEditMode ? (
          <>
            <div className="flex relative">
              <span
                className="block w-full border rounded-md p-2 h-12 bg-white truncate select-none cursor-pointer text-sm"
                onClick={onEnterEditMode}
              >
                {selectedPlace.formatted_address}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="font-poppins">
              <div className="flex relative">
                <input
                  ref={searchInputRef}
                  type="address"
                  value={currentValue}
                  onChange={onInputChange}
                  placeholder="ex: 1 Rue de la Paix, 75002 Paris"
                  className={classNames(
                    "w-full",
                    "rounded-lg",
                    "p-2",
                    "m-2",
                    "h-12",
                    "text-sm",
                    "border-2",
                    "border-[#00DABE]",
                    "focus:outline-none",
                    "focus:ring-2",
                    "focus:ring-[#00DABE]",
                    "focus:border-transparent",
                    "placeholder-gray-400",
                    "shadow-sm",
                    "[&+button]:my-4",
                    "[&+button]:mx-4",
                    "[&:focus+button]:my-4",
                    "[&:focus+button]:mx-4"
                  )}
                  onFocus={onInputFocused}
                  onBlur={onInputBlurred}
                />
                <CloseButton onClick={onClearHandler} />
              </div>

              {(isInputFocused || externalInputChanged) &&
                placePredictions.length > 0 && (
                  <div className="relative min-h-5">
                    <Loading isLoading={isPlacePredictionsLoading}>
                      <PlacesSearchResults
                        places={placePredictions}
                        onSelect={onSelect}
                      />
                    </Loading>
                  </div>
                )}
            </div>
          </>
        )}
      </>
    );
  }
);

export const CustomAddressInputWithContext = forwardRef<
  AddressInputRef,
  AddressInputProps
>((props: AddressInputProps, ref) => {
  const { fullTextAddress } = useAddressCoverage();
  return (
    <>
      <Component {...props} value={fullTextAddress} ref={ref} />
    </>
  );
});

export default Component;
