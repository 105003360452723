import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./AppDemo";
// import NewApp from "./pages/final";

Sentry.init({
  dsn: "https://2c7b55561a1d43459a99f7e8d8776bd9@o4505193370288128.ingest.sentry.io/4505193372385280",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// root.render(window.location.href.includes("demo") ? <AppDemo /> : <App />);
root.render(<App />);

// root.render(
//   <>
//     <div
//       style={{
//         display: "flex",
//       }}
//     >
//       <App />
//       <NewApp />
//     </div>
//   </>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
