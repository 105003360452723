import { Header } from "../components/header-demo";
import { OrderDetails } from "../components/order-details";
import { useTemporaryOrderContext } from "../contexts/temporary-order.context";
import { NotFoundPage } from "./NotFoundPage";

export function OrderTemporaryPage() {
  const { order } = useTemporaryOrderContext();

  if (!order) {
    return <NotFoundPage />;
  }

  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header
        title="Récapitulatif de votre demande"
        navigateHomeOnLogoClick={true}
      />
      <div className="pb-2">
        <p className="font-bold text-center">C'est tout bon</p>
        <p className="text-center">Votre formulaire est maintenant complet.</p>
        <p className="text-center">
          Nous vous recontactons dans les plus brefs délais afin de planifier au
          mieux l'enlèvement de votre électroménager.
        </p>
      </div>
      <OrderDetails order={order} showEditButtons={false} />
    </main>
  );
}
