import { toast, Toaster } from "sonner";

export const useToast = toast;

export default function Component() {
  return (
    <>
      <Toaster
        position={"bottom-right"}
        expand={false}
        closeButton={true}
        dir={"ltr"}
      />
    </>
  );
}
