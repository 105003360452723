import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Header } from "../components/header-demo";
import { CancelOrderButton } from "../components/cancel-order-button";
import { OrderDetails } from "../components/order-details";
import { useExistingOrderContext } from "../contexts/existing-order.context";
import { cancelOrder } from "../api/order.api";
import routes from "../routes";

export function EditOrderPage() {
  const navigate = useNavigate();
  const order = useExistingOrderContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: cancelOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getOrder", order.token],
      });
      navigate(routes.cancelConfirmed);
    },
  });

  function handleCancelOrder() {
    mutation.mutate(order.token);
  }

  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header title="Récapitulatif de votre demande" />
      <OrderDetails order={order} showEditButtons={true} />
      <div className="pt-4">
        <CancelOrderButton handleCancelOrder={handleCancelOrder} />
      </div>
    </main>
  );
}
