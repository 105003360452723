import { useEffect, useRef, useState } from "react";
import Wave from "../assets/wave";
import config from "../../../../utils/config";
import Autocomplete from "react-google-autocomplete";
import { useToast } from "../../../../components/ToasterProvider";
export default function Component() {
  const frame = useRef<HTMLIFrameElement | null>(null);
  const [currentAddress, setCurrentAddress] = useState<string>("");

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.data.type === "address") {
        useToast.success(e.data.address);
      }
      if (e.data.type === "google_address") {
        useToast.success(
          //e.data.details.formatted_address
          `Received google address: <br/>
          ${e.data.details.address.formatted_address}`
        );
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  return (
    <main className="w-full">
      <section className="w-[1179px] mx-auto my-0 relative">
        <div>
          <Wave className="absolute -left-[2.25rem] -top-[7rem] -z-10" />
        </div>
        <h5 className="w-[896px] text-yellow-400 text-[53px] font-normal font-['jdme'] tracking-[5.30px] tracking-widest mt-[53px] ml-[210px] mb-[116px]">
          Faites un geste solidaire,
          <br />
          donnez votre électroménager
        </h5>
        <div className="w-full flex items-center justify-center mb-[60px] flex-col gap-3 ">
          <div className="bg-[#C6C6C6] p-10 rounded-lg border border-[#888]">
            <div className="flex flex-col">
              <label htmlFor="fulltext" className="font-jdmeregular">
                Input Google Place
              </label>
              <Autocomplete
                apiKey={config.GOOGLE_MAPS_API_KEY}
                className="border py-2 px-5 w-[500px] rounded-md"
                onPlaceSelected={(place: any) => {
                  const payload = JSON.parse(JSON.stringify(place));
                  //(YOLO) - comment to test with formatted_address
                  delete payload.formatted_address;

                  // setCurrentAddressPlace(payload);
                  frame.current?.contentWindow?.postMessage({
                    type: "google_address",
                    address: payload,
                  });
                }}
                options={{
                  types: ["geocode"],
                  componentRestrictions: { country: "fr" },
                }}
                placeholder="ex: 1 Rue de la Paix, 75002 Paris"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="fulltext" className="font-jdmeregular">
                Input full text
              </label>
              <input
                type="address"
                name="fulltext"
                className="border py-2 px-5 w-[500px] rounded-md"
                placeholder="Votre code postal"
                value={currentAddress}
                onChange={(e) => {
                  e.preventDefault();
                  setCurrentAddress(e.target.value);
                  (
                    document.getElementById("jdme") as HTMLIFrameElement
                  )?.contentWindow?.postMessage({
                    type: "address",
                    address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <section className="flex justify-evenly items-center">
          <div>
            <span className="text-[#00dabe] text-[33.91px] font-normal font-jdmeregular">
              ecosystem collecte chez vous,
            </span>
          </div>
          <div>
            <div
              className="w-[347px] h-[711px] p-[9px] bg-white	rounded-[10px]"
              style={{
                boxShadow: "0.00px 3.00px 10px 1px rgba(0,0,0,0.08)",
              }}
            >
              <div className="w-full">
                <h4 className="text-[#00dabe] text-[32.41px] font-normal font-jdmethin text-center my-[35px]">
                  Planifier ma collecte
                </h4>
              </div>
              <iframe
                id="jdme"
                ref={frame}
                src={config.FRONTEND_URL}
                className="w-[329px] h-[550px]"
                title="jdme"
              />
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}
