import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Checkbox } from "../ui/checkbox";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";

export const termsSchema = z.object({
  terms: z.object({
    isDonation: z.boolean().refine((value) => value === true, {
      message: "Veuillez accepter la condition",
    }),
    isReliable: z.boolean().refine((value) => value === true, {
      message: "Veuillez accepter la condition",
    }),
    isAccessible: z.boolean().refine((value) => value === true, {
      message: "Veuillez accepter la condition",
    }),
    isPlacedInMyHome: z.boolean().refine((value) => value === true, {
      message: "Veuillez accepter la condition",
    }),
  }),
});

export function TermsFields() {
  const form = useFormContext<z.infer<typeof termsSchema>>();

  return (
    <div className="space-y-2">
      <FormField
        control={form.control}
        name="terms.isDonation"
        render={({ field }) => (
          <FormItem className="rounded-md border bg-background p-4">
            <div className="flex flex-row items-start space-x-3">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <p className="text-sm font-medium">
                Je cède gratuitement et de manière définitive mon ou mes
                équipements à ecosystem.
              </p>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="terms.isReliable"
        render={({ field }) => (
          <FormItem className="rounded-md border bg-background p-4">
            <div className="flex flex-row items-start space-x-3">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <p className="text-sm font-medium">
                Je certifie sur l'honneur l'exactitude des renseignements
                fournis et m'engage à{" "}
                <b>
                  être disponible à la date et sur le créneau horaire entier
                </b>{" "}
                choisis.
              </p>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="terms.isAccessible"
        render={({ field }) => (
          <FormItem className="rounded-md border bg-background p-4">
            <div className="flex flex-row items-start space-x-3">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <p className="text-sm font-medium">
                Je m'engage à ce que mon électroménager soit débranché et
                accessible pour les équipes ecosystem.
              </p>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="terms.isPlacedInMyHome"
        render={({ field }) => (
          <FormItem className="rounded-md border bg-background p-4">
            <div className="flex flex-row items-start space-x-3">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <p className="text-sm font-medium">
                Je m'engage à garder mon appareil au sein de mon domicile (pas
                sur le trottoir).
              </p>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
