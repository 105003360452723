import axios from "axios";
import config from "../utils/config";
import type { Weekdays } from "../types";

type GetAddressCoverageResponse = {
  isCovered: boolean;
  slots: {
    id: string;
    start: string;
    end: string;
    enabledDays: Weekdays[];
  }[];
  interruptions: {
    id: string;
    startsAt: Date;
    endsAt: Date;
  }[];
};

export const getAddressCoverage = async (postalCode: string) => {
  const response = await axios
    .create({ baseURL: config.API_URL })
    .get<GetAddressCoverageResponse>("/jdme2/address-coverage", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer d2e621a6646a4211768cd68e26f21228a81`,
      },
      params: {
        postalCode,
      },
    });

  if (response.status !== 200) {
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }

  return response.data;
};

type RequestAddressCoverageBody = {
  postalCode: string;
  city: string;
  email: string;
  phone?: string | null;
};

export const requestAddressCoverage = async (
  body: RequestAddressCoverageBody
) => {
  const response = await axios
    .create({ baseURL: config.API_URL })
    .post("/address-requests", body);

  if (response.status !== 201 && response.status !== 200) {
    throw new Error("Une erreur est survenue, veuillez réessayer");
  }

  return response.data;
};
