import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { HomeType } from "../../types";
import { APARTEMENT_MAX_FLOOR } from "../../utils/constants";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Switch } from "../ui/switch";

export const contactHomeSchema = z.object({
  contact: z
    .object({
      homeType: z.nativeEnum(HomeType, {
        required_error: "Veuillez renseigner le type de logement",
        invalid_type_error: "Veuillez renseigner un type de logement valide",
      }),
      hasElevator: z.boolean().optional(),
      floor: z.string().optional(),
      doorCode: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.homeType === HomeType.Apartment) {
        // Check if hasElevator is defined
        if (data.hasElevator === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Une erreur est survenue, veuillez rafraichir la page.",
            path: ["hasElevator"],
          });
        }
        // Check if floor is defined
        if (data.floor === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Une erreur est survenue, veuillez rafraichir la page.",
            path: ["floor"],
          });
        } else {
          // Check if floor is "RDC" or a number
          if (data.floor !== "RDC" && isNaN(+data.floor)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Veuillez renseigner un étage valide",
              path: ["floor"],
            });
          } else {
            // Check if floor is between 0 and maxFloor
            const maxFloor = data.hasElevator
              ? APARTEMENT_MAX_FLOOR.WITH_ELEVATOR
              : APARTEMENT_MAX_FLOOR.WITHOUT_ELEVATOR;
            const floor = +data.floor;
            if (floor < 1 || floor > maxFloor) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `L'étage est limité à ${maxFloor}`,
                path: ["floor"],
              });
            }
          }
        }
      }
    }),
});

export function ContactHomeFields() {
  const { control, watch, setValue } =
    useFormContext<z.infer<typeof contactHomeSchema>>();

  const [homeType, hasElevator, floor] = watch([
    "contact.homeType",
    "contact.hasElevator",
    "contact.floor",
  ]);

  // Reset appartement's specific fields when homeType changes to maison
  useEffect(() => {
    if (homeType === HomeType.House) {
      setValue("contact.hasElevator", false);
      setValue("contact.floor", "RDC");
      setValue("contact.doorCode", "");
    }
  }, [homeType, setValue]);

  const maxFloor = useMemo(
    () =>
      hasElevator
        ? APARTEMENT_MAX_FLOOR.WITH_ELEVATOR
        : APARTEMENT_MAX_FLOOR.WITHOUT_ELEVATOR,
    [hasElevator]
  );

  useEffect(() => {
    if (floor !== undefined && floor !== "RDC" && +floor > maxFloor) {
      alert(`L'étage est limité à ${maxFloor} sans ascenseur.`);
      setValue("contact.floor", maxFloor.toString());
    }
  }, [maxFloor, floor, setValue]);

  return (
    <>
      <FormField
        control={control}
        name="contact.homeType"
        render={({ field }) => (
          <FormItem>
            <Select
              onValueChange={field.onChange}
              defaultValue={field.value}
              value={field.value}
            >
              <FormControl>
                <SelectTrigger className="h-12">
                  <SelectValue placeholder="Sélectionnez un type de logement" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value={HomeType.House}>{HomeType.House}</SelectItem>
                <SelectItem value={HomeType.Apartment}>
                  {HomeType.Apartment}
                </SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      {homeType === HomeType.Apartment && (
        <>
          <FormField
            control={control}
            name="contact.hasElevator"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-2 space-y-0 p-2">
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>Ascenseur</FormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="contact.floor"
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="h-12">
                      <SelectValue placeholder="Sélectionnez l'étage" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="RDC">RDC</SelectItem>
                    {Array.from({ length: maxFloor }, (_, i) => i + 1).map(
                      (floor) => (
                        <SelectItem key={floor} value={String(floor)}>
                          {floor}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="contact.doorCode"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Code porte" className="h-12" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </>
  );
}
