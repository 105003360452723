export default function Asset({ className = "" }) {
  return (
    <svg
      preserveAspectRatio="none"
      data-bbox="0 0 372.9 161.83"
      viewBox="0 0 372.9 161.83"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      className={className}
    >
      <g>
        <path
          d="M0 9.3c33.37 89.1 119.3 152.53 220 152.53a234 234 0 0 0 152.9-56.58V0H0z"
          fill="#00dabe"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
}
