import clsx, { ClassValue } from "clsx";
import { setHours } from "date-fns";
import { twMerge } from "tailwind-merge";

import { APIOrder } from "../api/order.api";
import { Address } from "../hooks/order.hook";
import { FormartedSlot, HomeType, Order, Slot } from "../types";

export const addressFromGoogleMaps = (value: any) => {
  const address: Address = {
    street: "",
    city: "",
    postalCode: "",
  };
  address.latitude = value.geometry.location.lat();
  address.longitude = value.geometry.location.lng();
  address.coordinates = {
    lat: value.geometry.location.lat(),
    lng: value.geometry.location.lng(),
  };
  for (const component of value.address_components) {
    if (component.types.includes("locality")) {
      address.city = component.long_name;
    }
    if (component.types.includes("postal_code")) {
      address.postalCode = component.long_name;
    }
    if (component.types.includes("route")) {
      address.street += " " + component.long_name;
    }
    if (component.types.includes("country")) {
      address.country = component.long_name;
    }
    if (component.types.includes("street_number")) {
      address.street += " " + component.long_name;
    }
  }
  address.street = address.street.trim();
  return address;
};

export const formatSlot = (slot: Slot): FormartedSlot => {
  const startHour = parseInt(slot.start.split(":")[0]);
  const endHour = parseInt(slot.end.split(":")[0]);
  return {
    ...slot,
    startHour,
    endHour,
    label: `${startHour}-${endHour}h`,
  };
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isWindowInIframe(): boolean {
  return window.location !== window.parent.location;
}

export function formatAPIOrder(token: string, orderAPI: APIOrder): Order {
  const products = orderAPI.packages.flatMap((p) => p.products);

  return {
    orderId: orderAPI.orderId,
    token,
    address: {
      street: orderAPI.picking.address.addressLine1,
      city: orderAPI.picking.address.city,
      postalCode: orderAPI.picking.address.postalCode,
      country: orderAPI.picking.address.country,
      latitude: orderAPI.picking.address.latitude,
      longitude: orderAPI.picking.address.longitude,
    },
    meeting: {
      date: setHours(new Date(orderAPI.picking.interval[0].start), 0),
      slot: "-1", // API does not store the slot after the order is created
      interval: {
        start: orderAPI.picking.interval[0].start,
        end: orderAPI.picking.interval[0].end,
      },
    },
    appliances: products.map((a) => ({
      id: a.id,
      displayName: a.label,
      name: a.type,
      quantity: a.quantity,
      state: a.state,
      age: a.age,
    })),
    contact: {
      firstName: orderAPI.picking.contact.firstName,
      lastName: orderAPI.picking.contact.lastName,
      email: orderAPI.picking.contact.email,
      phone: orderAPI.picking.contact.phone,
      homeType:
        orderAPI.picking.address.doorCode ||
        orderAPI.picking.address.comment?.includes("Code porte") ||
        orderAPI.picking.address.elevator ||
        (orderAPI.picking.address.floor !== "RDC" &&
          +orderAPI.picking.address.floor > 0)
          ? HomeType.Apartment
          : HomeType.House,
      floor: orderAPI.picking.address.floor,
      elevator: orderAPI.picking.address.elevator,
      doorCode:
        orderAPI.picking.address.doorCode ||
        orderAPI.picking.address.comment?.replace("Code porte: ", ""),
    },
  };
}
