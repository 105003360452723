import React from "react";

import { Order } from "../types";

interface TemporaryOrder {
  order: Order | null;
  setOrder: (order: Order) => void;
}

const TemporaryOrderContext = React.createContext<TemporaryOrder | null>(null);

export const useTemporaryOrderContext = () => {
  const temporaryOrder = React.useContext(TemporaryOrderContext);
  if (!temporaryOrder) {
    throw new Error("TemporaryUserContext: No value provided");
  }
  return temporaryOrder;
};

export const TemporaryOrderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [order, setOrder] = React.useState<Order | null>(null);

  return (
    <TemporaryOrderContext.Provider
      value={{
        order,
        setOrder,
      }}
    >
      {children}
    </TemporaryOrderContext.Provider>
  );
};
