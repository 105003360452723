import "./index.css";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Footer from "./components/Footer";

import ToasterProvider from "../../components/ToasterProvider";

export default function Page() {
  return (
    <>
      <ToasterProvider />
      <div className="w-full">
        <Header />
        <MainContent />
        <Footer />
      </div>
    </>
  );
}

export const Router: ReactRouterEntry[] = [
  ...["landing"].map((path) => ({
    path,
    element: <Page />,
    // children: [
    //   { index: true, element: <EditOrderPage /> },
    //   { path: "mobile", element: <EditOrderPage /> },
    // ],
  })),
];

export interface ReactRouterEntry {
  path: string;
  element: JSX.Element;
  children?: ReactRouterEntry[];
}
