import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { requestAddressCoverage } from "../libs/address-coverage.lib";
import { Address } from "../types";
import { validatePhone } from "../utils/validation";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { Input } from "./ui/input";

const formSchema = z.object({
  email: z
    .string({
      required_error: "Veuillez renseigner votre adresse mail",
    })
    .trim()
    .min(1, { message: "Veuillez renseigner votre adresse mail" })
    .email({ message: "Veuillez renseigner une adresse mail valide" }),
  phone: z
    .string()
    .trim()
    .optional()
    .refine((value) => !value || validatePhone(value), {
      message: "Veuillez renseigner un numéro de téléphone valide",
    }),
});

type FormType = z.infer<typeof formSchema>;

type AddressRequestDialogProps = {
  address: Address;
};

export function AddressRequestDialog({ address }: AddressRequestDialogProps) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });
  const mutation = useMutation({
    mutationFn: requestAddressCoverage,
    onSuccess: () => {
      navigate("/address-requested");
    },
  });

  function onSubmit(values: FormType) {
    mutation.mutate({
      postalCode: address.postalCode,
      city: address.city,
      email: values.email,
      phone: values.phone || null,
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-md">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <DialogHeader className="space-y-4">
              <DialogTitle>Nous ne sommes pas encore là</DialogTitle>
              <p className="text-2xl font-bold text-primary">
                {address.postalCode} {address.city}
              </p>
              <p>
                Mais on y travaille ! Nous pouvons vous envoyer un message dès
                que nous serons présents dans cette zone.
              </p>
            </DialogHeader>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="email"
                      placeholder="Adresse mail"
                      autoComplete="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="tel"
                      placeholder="Téléphone (facultatif)"
                      autoComplete="tel"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {mutation.isError && (
              <div className="bg-red-100 p-2 rounded-md text-red-500">
                Une erreur est survenue lors de votre demande.
              </div>
            )}
            <DialogFooter>
              <Button
                type="submit"
                className="w-full"
                disabled={mutation.isPending}
              >
                Envoyez-moi un message
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
