export const isStaging = process.env.REACT_APP_ENV === "staging";
export const isDevelopment = process.env.REACT_APP_ENV === "development";
export const isProduction = process.env.REACT_APP_ENV === "production";

const config = {
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  API_URL: process.env.REACT_APP_API_URL || "",
  TMS_API_KEY:
    process.env.REACT_APP_TMS_API_KEY || "d2e621a6646a4211768cd68e26f21228a81",
  IS_FEATURE_SMALL_APPLIANCES_ENABLED:
    process.env.REACT_APP_IS_FEATURE_SMALL_APPLIANCES_ENABLED === "true",
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || "http://localhost:3006",
};

export default config;
