import { Address, Part } from "../contracts";

export const getFormattedAddress = (
  place: google.maps.places.AutocompletePrediction
) => {
  return place.matched_substrings.reduce(
    (acc, match, index) => {
      const textPart = place.description.substring(
        acc.currentOffset,
        match.offset
      );

      acc.currentOffset = match.offset + match.length;

      const matchPart = place.description.substring(
        match.offset,
        match.offset + match.length
      );

      acc.rest = place.description.substring(match.offset + match.length);

      textPart.length > 0 &&
        acc.parts.push(() => (
          <span key={`text-part-${index}`}>{textPart}</span>
        ));

      acc.parts.push(() => (
        <span key={index} className="font-bold">
          {matchPart}
        </span>
      ));

      return acc;
    },
    {
      rest: place.description,
      currentOffset: 0,
      parts: [] as Part[],
      debug: [] as any[],
    }
  );
};

export const mapPlaceToAddress = (
  place: google.maps.places.PlaceResult
): Address => {
  const route = place.address_components?.find((it) =>
    it.types.includes("route")
  )?.long_name;
  const streetNumber = place.address_components?.find((it) =>
    it.types.includes("street_number")
  )?.long_name;
  const street = `${streetNumber} ${route}`;
  return {
    street,
    city: place.address_components?.find((it) => it.types.includes("locality"))
      ?.long_name,
    postalCode: place.address_components?.find((it) =>
      it.types.includes("postal_code")
    )?.long_name,
    country: place.address_components?.find((it) =>
      it.types.includes("country")
    )?.long_name,
    latitude: getCoordinate(place.geometry?.location?.lat),
    longitude: getCoordinate(place.geometry?.location?.lng),
    formatted_address: place.formatted_address,
  } as Address;
};

const getCoordinate = (
  property: Function | number | undefined
): number | undefined => {
  return typeof property === "function" ? property() : property;
};
