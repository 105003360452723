export function NotFoundPage() {
  return (
    <div className="max-w-md mx-auto flex flex-col text-center space-y-4">
      <p className="text-xl font-bold">Désolé</p>
      <p>La page que vous avez demandé n'a pas été trouvé.</p>
      <p>
        Si vous souhaitez modifier votre demande de collecte existante, veuillez
        suivre le lien que vous avez reçu par email.
      </p>
      <p>
        Si vous souhaitez programmer une nouvelle collecte, rendez-vous sur{" "}
        <a
          href="https://jedonnemonelectromenager.fr"
          target="_top"
          className="text-primary"
        >
          https://jedonnemonelectromenager.fr
        </a>
      </p>
    </div>
  );
}
