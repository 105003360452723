export default function CancelOrderConfirmedPage() {
  return (
    <div className="max-w-md mx-auto flex flex-col text-center space-y-4">
      <p className="text-xl font-bold">C'est tout bon</p>
      <p>Votre annulation a bien été prise en compte.</p>
      <p>
        N'hésitez pas à programmer une nouvelle collecte sur{" "}
        <a
          href="https://jedonnemonelectromenager.fr"
          target="_top"
          className="text-primary"
        >
          https://jedonnemonelectromenager.fr
        </a>
      </p>
    </div>
  );
}
