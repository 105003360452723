import { parsePhoneNumber } from "libphonenumber-js";

export const validatePhone = (value: string) => {
  try {
    const phoneNumber = parsePhoneNumber(value, "FR");
    return (
      phoneNumber.isValid() &&
      phoneNumber.isPossible() &&
      phoneNumber.country === "FR"
    );
  } catch (e) {
    return false;
  }
};
