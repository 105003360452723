import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { getOrder } from "../api/order.api";
import { Order } from "../types";

const ExistingOrderContext = React.createContext<Order | null>(null);

export const useExistingOrderContext = () => {
  const context = React.useContext(ExistingOrderContext);
  if (!context) {
    throw new Error("useExistingOrderContext was used outside of its Provider");
  }
  return context;
};

export const ExistingOrderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { token } = useParams<{ token: string }>();
  const orderToken = token || localStorage.getItem("orderToken") || "";

  const existingOrderQuery = useQuery({
    queryKey: ["getOrder", orderToken],
    queryFn: async () => {
      const order = await getOrder(orderToken);
      localStorage.setItem("orderToken", orderToken);
      return order;
    },
    retry: false,
  });

  if (existingOrderQuery.isLoading) {
    return <p>Chargement en cours...</p>;
  }

  if (existingOrderQuery.isError) {
    return <p>Une erreur est survenue: {existingOrderQuery.error.message}</p>;
  }

  if (!existingOrderQuery.data) {
    return <p>Commande introuvable</p>;
  }

  return (
    <ExistingOrderContext.Provider
      value={{ ...existingOrderQuery.data, token: orderToken }}
    >
      {children}
    </ExistingOrderContext.Provider>
  );
};
