import { useAddressCoverage } from "../contexts/address-coverage-demo.context";

export default function Component() {
  const { addressLoading } = useAddressCoverage();

  if (addressLoading === false) return null;

  return (
    <section className="absolute flex w-full h-full items-center justify-center bg-white bg-opacity-75 z-50 top-0 left-0 border rounded-md">
      <span className="w-full h-full flex align-middle justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          className="w-20"
        >
          <circle
            fill="#00DEFF"
            stroke="#00DEFF"
            strokeWidth="15"
            r="15"
            cx="40"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle
            fill="#00DEFF"
            stroke="#00DEFF"
            strokeWidth="15"
            r="15"
            cx="100"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle
            fill="#00DEFF"
            stroke="#00DEFF"
            strokeWidth="15"
            r="15"
            cx="160"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="2"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg>
      </span>
    </section>
  );
}
