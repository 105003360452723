import { CreateOrderForm } from "../components/create-order-form";
import { Header } from "../components/header-demo";
import { isWindowInIframe } from "../utils/functions";

export function CreateOrderPage() {
  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header title={isWindowInIframe() ? "" : "Planifier ma collecte"} />
      <CreateOrderForm />
    </main>
  );
}
