import { X } from "lucide-react";

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      className="absolute flex items-center justify-center right-0 top-0 h-8 w-8 p-0 bg-[#f5f5f5] text-[rgba(0,0,0,.5)] rounded-md hover:bg-gray-100 hover:text-gray-800 hover:shadow-md hover:ring-1 hover:ring-gray-200 focus:outline-none hover:ring-opacity-50 hover:border"
      type="button"
      onClick={onClick}
    >
      <X className="h-4 w-4" strokeWidth={3} />
    </button>
  );
};
