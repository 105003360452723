import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/images/jdme-logo.webp";
import { isWindowInIframe } from "../utils/functions";
import { Button } from "./ui/button";

interface HeaderProps {
  title: string;
  backUrl?: string;
  navigateHomeOnLogoClick?: boolean;
}

export function Header({
  title,
  backUrl,
  navigateHomeOnLogoClick,
}: HeaderProps) {
  const navigate = useNavigate();
  const isIframe = isWindowInIframe();

  if (isIframe) {
    if (!(title || backUrl)) return null;

    return (
      <div>
        <div className="flex justify-between items-center p-2">
          {backUrl && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate(backUrl)}
            >
              <ArrowLeft className="h-4 w-4" />
            </Button>
          )}
          <p className="text-center text-primary text-lg font-bold mx-auto">
            {title}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="max-w-[350px] mx-auto p-2"
      onClick={() => {
        if (navigateHomeOnLogoClick) {
          window.location.href = "https://www.jedonnemonelectromenager.fr/";
        }
      }}
    >
      <img src={logo} alt="logo" draggable={false} />
    </div>
  );
}
