import { useMutation, useQueryClient } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Order } from "../types";
import { ContactFields, contactSchema } from "./fields/contact-fields";
import { Button } from "./ui/button";
import { Form } from "./ui/form";
import { updateOrder } from "../api/order.api";

type FormType = z.infer<typeof contactSchema>;

interface EditOrderContactFormProps {
  order: Order;
  onEdit: () => void;
}

export function EditOrderContactForm({
  order,
  onEdit,
}: EditOrderContactFormProps) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getOrder", order.token],
      });
      onEdit();
    },
  });
  const form = useForm<FormType>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      contact: {
        firstName: order.contact.firstName,
        lastName: order.contact.lastName,
        email: order.contact.email,
        phone: order.contact.phone,
        homeType: order.contact.homeType,
        hasElevator: order.contact.elevator,
        floor: order.contact.floor,
        doorCode: order.contact.doorCode,
      },
    },
  });

  function onSubmit(values: FormType) {
    const newOrder = {
      ...order,
      contact: {
        firstName: values.contact.firstName,
        lastName: values.contact.lastName,
        email: values.contact.email,
        phone: values.contact.phone,
        homeType: values.contact.homeType,
        elevator: values.contact.hasElevator,
        floor: values.contact.floor,
        doorCode: values.contact.doorCode,
      },
    };

    mutation.mutate(newOrder);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 mx-2 mt-4"
      >
        <div className="bg-gray-100 p-2 rounded-md space-y-2">
          <ContactFields />
        </div>
        {mutation.isError && (
          <div className="bg-red-100 p-2 rounded-md text-red-500">
            Une erreur est survenue lors de la modification de la commande.
          </div>
        )}
        <Button type="submit" disabled={mutation.isPending}>
          Modifier
        </Button>
      </form>
    </Form>
  );
}
