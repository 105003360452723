import { getFormattedAddress } from "../services";

export const PlacesSearchResults = ({
  places,
  onSelect,
}: {
  places: google.maps.places.AutocompletePrediction[];
  onSelect: (place: google.maps.places.AutocompletePrediction) => void;
}) => {
  return (
    <ul
      className="absolute z-10 w-full bg-white border rounded-md shadow-md"
      onMouseDown={(e) => e.preventDefault()}
    >
      {places.map((place) => {
        const formattedAddress = getFormattedAddress(place);
        return (
          <li
            key={place.place_id}
            className="p-2 cursor-pointer hover:bg-gray-100 text-sm"
            onClick={(e) => {
              onSelect(place);
            }}
          >
            {formattedAddress.parts.map((it) => it())}
            {formattedAddress.rest}
          </li>
        );
      })}
    </ul>
  );
};
