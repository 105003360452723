import secheLing from "../assets/images/Sèche-linge.svg";
import frigo from "../assets/images/frigo.svg";
import frigoUs from "../assets/images/frigo-us.svg";
import laveVaisselle from "../assets/images/Lave-vaisselle_ouvert.svg";
import laveLinge from "../assets/images/Lave-linge.svg";
import hote from "../assets/images/Hotte aspirante.svg";
import four from "../assets/images/Four-encastrable.svg";
import cuisiniere from "../assets/images/Cuisinière avec plaques à induction.svg";
import congelateur from "../assets/images/Congélateur.svg";
import caveVin from "../assets/images/Caves à vin.svg";
import plaque from "../assets/images/Plaques.png";
import fourMicroOndes from "../assets/images/Micro-ondes.png";
import reparable from "../assets/images/reparable.svg";
import recyclage from "../assets/images/recyclage.svg";
import fonctionnel from "../assets/images/fontionnel.svg";
import moins2 from "../assets/images/moins2.svg";
import moins5 from "../assets/images/moin5.svg";
import plus6 from "../assets/images/plus6.svg";
import plus10 from "../assets/images/plus10.svg";
import { CustomSelectOption } from "../components/CustomSelect";
import { Weekdays } from "../types";

export const applianceList = [
  { name: "Lave-linge", slug: "washing-machine", src: laveLinge },
  { name: "Lave-vaisselle", slug: "dishwasher", src: laveVaisselle },
  { name: "Réfrigérateur", slug: "refrigerator", src: frigo },
  { name: "Réfrigérateur américain", slug: "american-fridge", src: frigoUs },
  { name: "Four", slug: "built-in-oven", src: four },
  { name: "Four à micro-ondes", slug: "microwave", src: fourMicroOndes },
  { name: "Sèche-linge", slug: "dryer", src: secheLing },
  { name: "Cuisinière", slug: "stove", src: cuisiniere },
  { name: "Congélateur", slug: "freezer", src: congelateur },
  { name: "Plaque de cuisson", slug: "cooking-hob", src: plaque },
  { name: "Hotte aspirante", slug: "extractor-hood", src: hote },
  { name: "Cave à vin", slug: "wine-cellar", src: caveVin },
];

export const stateList: CustomSelectOption[] = [
  {
    value: "Fonctionnel",
    label: "Fonctionnel",
    imgSrc: fonctionnel,
  },
  {
    value: "Réparable",
    label: "Réparable",
    imgSrc: reparable,
  },
  {
    value: "Bon pour recyclage",
    label: "Bon pour recyclage",
    imgSrc: recyclage,
  },
];

export const ageList: CustomSelectOption[] = [
  {
    label: "Moins de 2 ans",
    value: "Moins de 2 ans",
    imgSrc: moins2,
  },
  {
    label: "Entre 2 et 5 ans",
    value: "Entre 2 et 5 ans",
    imgSrc: moins5,
  },
  {
    label: "Entre 6 et 10 ans",
    value: "Entre 6 et 10 ans",
    imgSrc: plus6,
  },
  {
    label: "Plus de 10 ans",
    value: "Plus de 10 ans",
    imgSrc: plus10,
  },
];

export const AVAILABLE_POSTAL_CODES_GROUPE = [
  "75",
  "92",
  "94",
  "93",
  "91",
  "78",
  "95",
];

export interface ITimeslot {
  value: string;
  label: string;
  startHour: string;
  endHour: string;
  availableDays: Weekdays[];
}

export const WEEKDAYS = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
] as const;

export const APARTEMENT_MAX_FLOOR = {
  WITH_ELEVATOR: 39,
  WITHOUT_ELEVATOR: 10,
};
