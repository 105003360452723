import { useNavigate } from "react-router-dom";

import { EditOrderAppliancesForm } from "../components/edit-order-appliances-form";
import { Header } from "../components/header-demo";
import { useExistingOrderContext } from "../contexts/existing-order.context";
import routes from "../routes";

export function EditOrderAppliancesPage() {
  const order = useExistingOrderContext();
  const navigate = useNavigate();

  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header title="Modifier vos appareils" backUrl={routes.edit} />
      <EditOrderAppliancesForm
        order={order}
        onEdit={() => navigate(routes.edit)}
      />
    </main>
  );
}
