import { Minus, Plus } from "lucide-react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

export interface QuantityInputProps {
  quantity: number;
  onQuantityChange: (quantity: number) => void;
  min?: number;
  max?: number;
}

export function QuantityInput({
  quantity,
  onQuantityChange,
  min,
  max,
}: QuantityInputProps) {
  function onMinusClick() {
    if (min !== undefined && quantity <= min) return;
    onQuantityChange(quantity - 1);
  }

  function onPlusClick() {
    if (max !== undefined && quantity >= max) return;
    onQuantityChange(quantity + 1);
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newQuantity = parseInt(event.target.value, 10) || 0;
    if (min !== undefined && newQuantity < min) {
      onQuantityChange(min);
      return;
    }
    if (max !== undefined && newQuantity > max) {
      onQuantityChange(max);
      return;
    }
    onQuantityChange(newQuantity);
  }

  return (
    <div className="flex flex-row gap-2">
      <Button
        type="button"
        variant="outline"
        size="icon"
        onClick={onMinusClick}
        disabled={min !== undefined && quantity <= min}
        className="disabled:opacity-0"
      >
        <Minus className="h-4 w-4" />
      </Button>
      <Input
        className="w-10 text-center"
        value={quantity}
        onChange={onInputChange}
      />
      <Button
        type="button"
        variant="outline"
        size="icon"
        onClick={onPlusClick}
        disabled={max !== undefined && quantity >= max}
        className="disabled:opacity-0"
      >
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  );
}
