export default function Component() {
  return (
    <header>
      <div className="w-[1920px] h-[91px] relative shadow-md">
        <div className="w-[1920px] h-[91px] left-0 top-0 absolute bg-white shadow" />
        <div className="w-[95px] h-[23px] left-[470px] top-[36px] absolute">
          <div className="w-[95px] h-[23px] left-0 top-0 absolute justify-center items-center inline-flex">
            <div className="grow shrink basis-0 self-stretch pr-[72px] justify-start items-center inline-flex">
              <div className="w-[23px] h-[23px] relative flex-col justify-start items-start flex" />
            </div>
          </div>
          <img
            className="w-[95px] h-[23px] left-0 top-0 absolute"
            src="https://static.wixstatic.com/media/55ea8c_cbe9a7d234074c2f9a7d69483c3285d2~mv2.png/v1/crop/x_3,y_0,w_743,h_180/fill/w_95,h_23,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Fichier%207.png"
            alt="logo"
          />
        </div>
        <img
          className="w-[628px] h-[50px] left-[646px] top-[20px] absolute"
          src="https://static.wixstatic.com/media/55ea8c_df293c09edd24d47a66a6efb30aef194~mv2.png/v1/fill/w_628,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/jdme_logo_1_ligne.png"
          alt="search"
        />
      </div>
    </header>
  );
}
