import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { validatePhone } from "../../utils/validation";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { ContactHomeFields, contactHomeSchema } from "./contact-home-fields";

export const contactSchema = contactHomeSchema.and(
  z.object({
    contact: z.object({
      firstName: z
        .string({ required_error: "Veuillez renseigner votre prénom" })
        .trim()
        .min(1, { message: "Veuillez renseigner votre prénom" }),
      lastName: z
        .string({ required_error: "Veuillez renseigner votre nom" })
        .trim()
        .min(1, { message: "Veuillez renseigner votre nom" }),
      email: z
        .string({
          required_error: "Veuillez renseigner votre adresse mail",
        })
        .trim()
        .email({
          message: "Veuillez renseigner une adresse mail valide",
        }),
      phone: z
        .string({ required_error: "Veuillez renseigner votre téléphone" })
        .trim()
        .min(1, { message: "Veuillez renseigner votre téléphone" })
        .refine(validatePhone, {
          message: "Veuillez renseigner un numéro de téléphone valide",
        }),
    }),
  })
);

export function ContactFields() {
  const form = useFormContext<z.infer<typeof contactSchema>>();

  return (
    <div className="space-y-2">
      <FormField
        control={form.control}
        name="contact.firstName"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder="Prénom"
                autoComplete="given-name"
                className="h-12"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contact.lastName"
        render={({ field }) => (
          <FormItem className="mt-0 space-y-0">
            <FormControl>
              <Input
                placeholder="Nom"
                autoComplete="family-name"
                className="h-12"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contact.email"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                type="email"
                placeholder="Adresse mail (ex: jeandupont@mail.com)"
                autoComplete="email"
                className="h-12"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="contact.phone"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                type="tel"
                placeholder="Téléphone (ex: 0601020304)"
                autoComplete="tel"
                className="h-12"
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <ContactHomeFields />
    </div>
  );
}
