import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import type { Order } from "../types";
import { AppliancesField, appliancesSchema } from "./fields/appliances-field";
import {
  SmallAppliancesFormField,
  smallAppliancesSchema,
} from "./fields/small-appliances-field";
import { Button } from "./ui/button";
import { Form } from "./ui/form";
import { updateOrder } from "../api/order.api";
import config from "../utils/config";

const formSchema = appliancesSchema.and(smallAppliancesSchema);

type FormSchema = z.infer<typeof formSchema>;

interface EditOrderAppliancesFormProps {
  order: Order;
  onEdit: () => void;
}

export function EditOrderAppliancesForm({
  order,
  onEdit,
}: EditOrderAppliancesFormProps) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getOrder", order.token],
      });
      onEdit();
    },
  });

  const defaultAppliances = useMemo(() => {
    return order.appliances
      .filter((a) => a.name !== "Petit électroménager")
      .reduce<FormSchema["appliances"]>((acc, a) => {
        const i = acc.findIndex((e) => e.type === a.name);
        if (i === -1) {
          acc.push({ type: a.name, label: a.name, quantity: 1 });
        } else {
          acc[i].quantity++;
        }
        return acc;
      }, []);
  }, [order.appliances]);

  const defaultSmallAppliancesQuantity = useMemo(() => {
    return order.appliances.filter((a) => a.name === "Petit électroménager")
      .length;
  }, [order.appliances]);

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      appliances: defaultAppliances,
      smallAppliances: {
        quantity: defaultSmallAppliancesQuantity,
      },
    },
  });

  function onSubmit(values: FormSchema) {
    if (values.smallAppliances.quantity > 0) {
      values.appliances.push({
        type: "small-appliance",
        label: "Petit électroménager",
        quantity: values.smallAppliances.quantity,
      });
    }

    const individualAppliances: Order["appliances"] = [];
    values.appliances.forEach((appliance) => {
      for (let i = 1; i <= appliance.quantity; i++) {
        individualAppliances.push({
          age: "unknown",
          state: "unknown",
          displayName:
            appliance.quantity > 1
              ? `${appliance.label} ${i}`
              : appliance.label,
          name: appliance.label,
        });
      }
    });

    const newOrder = {
      ...order,
      appliances: individualAppliances,
    };

    mutation.mutate(newOrder);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 mx-2 mt-4"
      >
        <div className="bg-gray-100 p-2 rounded-md space-y-2">
          <AppliancesField />
          {config.IS_FEATURE_SMALL_APPLIANCES_ENABLED && (
            <SmallAppliancesFormField />
          )}
        </div>
        {mutation.isError && (
          <div className="bg-red-100 p-2 rounded-md text-red-500">
            Une erreur est survenue lors de la modification de la commande.
          </div>
        )}
        <Button type="submit" disabled={mutation.isPending}>
          Modifier
        </Button>
      </form>
    </Form>
  );
}
