import axios from "axios";

import { Order } from "../types";
import config from "../utils/config";
import { formatAPIOrder } from "../utils/functions";

const api = axios.create({ baseURL: config.API_URL });

export const getOrder = async (token: string) => {
  try {
    const response = await api.get<APIOrder>("/jdme/order", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.status === "canceled") {
      throw new Error("Commande annulée");
    }
    return formatAPIOrder(token, response.data);
  } catch (error) {
    console.error(error);
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        throw new Error("La date pour modifier la commande est dépassée.");
      } else if (error.response?.status === 401) {
        throw new Error("Commande introuvable");
      }
    }
    throw error;
  }
};

export const createOrder = async (body: Order) => {
  try {
    const response = await api.post<{
      token?: string;
      hasAmericanFridge?: boolean;
      isApplianceNumberMoreThanThreshold?: boolean;
    }>("/jdme/order", body, {
      headers: {
        Authorization: `Bearer d2e621a6646a4211768cd68e26f21228a81`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("La commande n'a pas pu être créée");
  }
};

export const updateOrder = async (order: Order) => {
  try {
    await api.put<APIOrder>("/jdme/order", order, {
      headers: {
        Authorization: `Bearer ${order.token}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw new Error("La commande n'a pas pu être modifiée");
  }
};

export const cancelOrder = (token: string) => {
  try {
    return api.delete(`/jdme/order`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error(error);
    throw new Error("La commande n'a pas pu être annulée");
  }
};

export type APIOrder = {
  orderId: string;
  status: string;
  picking: {
    address: {
      addressLine1: string;
      city: string;
      postalCode: string;
      country: string;
      elevator: boolean;
      floor: string;
      doorCode: string;
      comment: string;
      latitude: number;
      longitude: number;
      // homeType: string ?? FIXME: info is missing
    };
    contact: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    interval: {
      start: string;
      end: string;
    }[];
  };
  packages: {
    products: {
      id: string;
      type: string;
      label: string;
      quantity: number;
      state: string;
      age: string;
    }[];
  }[];
};
