import { Header } from "../components/header-demo";
import { OrderDetails } from "../components/order-details";
import { useExistingOrderContext } from "../contexts/existing-order.context";

export function OrderSummaryPage() {
  const order = useExistingOrderContext();

  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header
        title="Récapitulatif de votre demande"
        navigateHomeOnLogoClick={true}
      />
      <div className="pb-2">
        <p className="font-bold text-center">C'est tout bon</p>
        <p className="text-center">
          Votre formulaire est maintenant complet. Nous vous remercions de nous
          confier votre appareil.
        </p>
      </div>
      <OrderDetails order={order} showEditButtons={true} />
    </main>
  );
}
