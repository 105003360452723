import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { ChevronUp, ChevronDown } from "lucide-react";

import { applianceList } from "../../utils/constants";
import { Button } from "../ui/button";
import { Collapsible, CollapsibleContent } from "../ui/collapsible";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { QuantityInput, QuantityInputProps } from "./quantity-input";

export const appliancesSchema = z.object({
  appliances: z
    .array(
      z.object({
        type: z.string(),
        label: z.string(),
        quantity: z.number().positive(),
      })
    )
    .min(1, "Veuillez renseigner au moins un appareil"),
});

type AppliancesSchema = z.infer<typeof appliancesSchema>;

export function AppliancesField() {
  const form = useFormContext<AppliancesSchema>();
  const [isExpanded, setIsExpanded] = useState(false);

  function onApplianceQuantityChange(
    fieldValue: AppliancesSchema["appliances"],
    appliance: (typeof applianceList)[number],
    quantity: number,
    setFieldValue: (value: AppliancesSchema["appliances"]) => void
  ) {
    const index = fieldValue.findIndex((a) => a.type === appliance.name);
    // If the appliance is not in the list, add it
    if (index === -1) {
      setFieldValue([
        ...fieldValue,
        {
          type: appliance.name,
          label: appliance.name,
          quantity,
        },
      ]);
      return;
    }
    // If the new quantity is 0, remove the appliance from the list
    if (quantity === 0) {
      setFieldValue(fieldValue.filter((a) => a.type !== appliance.name));
      return;
    }
    // Else, update the quantity
    setFieldValue(
      fieldValue.map((a, i) => (i === index ? { ...a, quantity } : a))
    );
  }

  return (
    <FormField
      control={form.control}
      name="appliances"
      render={({ field }) => {
        function renderAppliance(appliance: (typeof applianceList)[number]) {
          return (
            <ApplianceInput
              key={appliance.name}
              imageSrc={appliance.src}
              name={appliance.name}
              min={0}
              quantity={
                field.value.find((a) => a.type === appliance.name)?.quantity ??
                0
              }
              onQuantityChange={(quantity) =>
                onApplianceQuantityChange(
                  field.value,
                  appliance,
                  quantity,
                  field.onChange
                )
              }
            />
          );
        }

        const visibleAppliances = applianceList.slice(0, 5);
        const collapsedAppliances = applianceList.slice(5);

        return (
          <FormItem>
            <FormControl>
              <Collapsible open={isExpanded} className="space-y-2">
                {visibleAppliances.map(renderAppliance)}
                <CollapsibleContent className="space-y-2">
                  {collapsedAppliances.map(renderAppliance)}
                </CollapsibleContent>
                <Button
                  type="button"
                  variant="outline"
                  size="lg"
                  className="w-full"
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? <ChevronUp /> : <ChevronDown />}
                  {isExpanded ? "Voir moins" : "Voir tous les appareils"}
                </Button>
              </Collapsible>
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

interface ApplianceInputProps extends QuantityInputProps {
  imageSrc: string;
  name: string;
}

function ApplianceInput(props: ApplianceInputProps) {
  return (
    <div className="flex gap-2 justify-between items-center w-full rounded-md border border-input bg-background px-3 py-2 text-sm">
      <div className="flex gap-2 items-center">
        <img
          src={props.imageSrc}
          alt={props.name}
          className="w-12"
          draggable={false}
        />
        <p>{props.name}</p>
      </div>
      <QuantityInput {...props} />
    </div>
  );
}
