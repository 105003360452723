export default function AddressRequestedPage() {
  return (
    <div className="max-w-md mx-auto flex flex-col text-center space-y-4">
      <p className="text-xl font-bold">C'est tout bon</p>
      <p>
        Votre demande a bien été prise en compte.
        <br /> Nous vous enverrons un message dès que nous serons en mesure de
        collecter dans votre zone.
      </p>
      <p>
        A bientôt sur{" "}
        <a
          href="https://jedonnemonelectromenager.fr"
          target="_top"
          className="text-primary"
        >
          https://jedonnemonelectromenager.fr
        </a>
      </p>
    </div>
  );
}
