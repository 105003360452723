import { WEEKDAYS } from "./utils/constants";

export type Weekdays = (typeof WEEKDAYS)[number];

export type Area = {
  id: string;
  name: string;
  cities: string[];
  departments: string[];
  countries: string[];
  slots: Slot[];
  interruptions: Interruption[];
};

export type Slot = {
  id: string;
  start: string;
  end: string;
  enabledDays: Weekdays[];
};

export type FormartedSlot = Slot & {
  startHour: number;
  endHour: number;
  label: string;
};

export type Interruption = {
  id: string;
  startsAt: Date;
  endsAt: Date;
};

export type Order = {
  id?: string;
  token: string;
  orderId?: string; // client reference number
  address: Address;
  appliances: Appliance[];
  contact: Contact;
  meeting: Meeting;
};

export type Address = {
  id?: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
  coordinates?: {
    lat: number;
    lng: number;
  };
  area?: Area;
};

export type Appliance = {
  id?: string;
  name: string;
  displayName?: string;
  state?: string;
  age?: string;
};

export enum HomeType {
  House = "Maison",
  Apartment = "Appartement",
}

export type Contact = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  homeType: HomeType;
  floor?: string;
  elevator?: boolean;
  doorCode?: string;
};

export type Meeting = {
  id?: string;
  date: Date;
  slot: string;
  interval: {
    start: string;
    end: string;
  };
};
