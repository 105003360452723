import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import { HomeType, Order } from "../types";
import { Button } from "./ui/button";
import routes from "../routes";

const styles = {
  card: "border rounded-md overflow-hidden",
  cardHeader: "flex justify-between items-center p-2 bg-primary",
  cardTitle: "text-md text-primary-foreground font-bold",
  cardContent: "p-4",
};

interface OrderDetailsProps {
  order: Order;
  showEditButtons: boolean;
}

export function OrderDetails({ order, showEditButtons }: OrderDetailsProps) {
  const navigate = useNavigate();

  const { date, interval } = order.meeting;
  const options = { locale: fr };
  const formattedDate = format(date, "P", options);
  const formattedStartHour = format(new Date(interval.start), "k", options);
  const formattedEndHour = format(new Date(interval.end), "k", options);

  return (
    <div className="space-y-6">
      <div className={styles.card}>
        {/* PICKING */}
        <div className={styles.cardHeader}>
          <p className={styles.cardTitle}>Votre rendez-vous</p>
          {showEditButtons && (
            <Button
              variant="default"
              onClick={() => navigate(routes.editPicking)}
            >
              Modifier
            </Button>
          )}
        </div>
        <div className={styles.cardContent}>
          <p>
            Le {formattedDate} entre {formattedStartHour}h et {formattedEndHour}
            h
          </p>
        </div>
      </div>
      {/* CONTACT */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <p className={styles.cardTitle}>Vos coordonnées</p>
          {showEditButtons && (
            <Button
              variant="default"
              onClick={() => navigate(routes.editContact)}
            >
              Modifier
            </Button>
          )}
        </div>
        <div className={styles.cardContent}>
          <p>
            {order.contact.firstName} {order.contact.lastName}
          </p>
          <p>{order.contact.email}</p>
          <p>{order.contact.phone}</p>
          <p>{order.contact.homeType}</p>
          {order.contact.homeType === HomeType.Apartment && (
            <>
              <p>{order.contact.elevator ? "Ascenseur" : "Sans ascenseur"}</p>
              <p>Étage {order.contact.floor}</p>
              {order.contact.doorCode && (
                <p>Code porte {order.contact.doorCode}</p>
              )}
            </>
          )}
        </div>
      </div>
      {/* APPLIANCES */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <p className={styles.cardTitle}>Votre collecte</p>
          {showEditButtons && (
            <Button
              variant="default"
              onClick={() => navigate(routes.editAppliances)}
            >
              Modifier
            </Button>
          )}
        </div>
        <div className={styles.cardContent}>
          {order.appliances.map((appliance) => (
            <p key={appliance.id}>{appliance.name}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
