import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { EditOrderPickingForm } from "../components/edit-order-picking-form";
import { useAddressCoverage } from "../contexts/address-coverage-demo.context";
import { Header } from "../components/header-demo";
import routes from "../routes";
import { useExistingOrderContext } from "../contexts/existing-order.context";

export function EditOrderPickingPage() {
  const order = useExistingOrderContext();
  const navigate = useNavigate();

  const {
    fetchCoverage,
    error,
    loading,
    isCovered,
    minDate,
    maxDate,
    getSlotsForDate,
  } = useAddressCoverage();

  useEffect(() => {
    fetchCoverage(order.address.postalCode);
  }, [order, fetchCoverage]);

  if (error) {
    return (
      <main className="max-w-md mx-auto p-2 space-y-2">
        <Header title="Modifier votre rendez-vous" backUrl={routes.edit} />
        <p>Une erreur est survenue</p>
      </main>
    );
  }

  if (loading) {
    return <p>Vérification de votre adresse...</p>;
  }

  if (isCovered === false) {
    return (
      <main className="max-w-md mx-auto p-2 space-y-2">
        <Header title="Modifier votre rendez-vous" backUrl={routes.edit} />
        <p>Votre adresse n'est plus couverte</p>
      </main>
    );
  }

  return (
    <main className="max-w-md mx-auto p-2 space-y-2">
      <Header title="Modifier votre rendez-vous" backUrl={routes.edit} />
      <EditOrderPickingForm
        order={order}
        minDate={minDate}
        maxDate={maxDate}
        getSlotsForDate={getSlotsForDate}
        onEdit={() => navigate(routes.edit)}
        onDateChange={() => ({ status: true, message: "SUCCESS" })}
      />
    </main>
  );
}
