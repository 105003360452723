import classNames from "classnames";

export default function Component() {
  return (
    <>
      <section
        className={classNames(
          "[&_p]:my-3",
          "[&_p]:ml-2",
          "[&_p]:text-sm",
          "[&_h2]:font-bold",
          "[&_h2]:text-sm",
          "[&_h2]:text-[#05bca4]",
          "[&_h3]:font-bold",
          "[&_h3]:text-sm",
          "[&_h3]:text-[#05bca4]",
          "[&_ol]:list-disc"
        )}
      >
        <h2>Comment ça fonctionne :</h2>
        <p>
          Quand vous commencez à taper votre adresse dans le champ dédié, le
          système génère dynamiquement des suggestions basées sur les caractères
          que vous avez entrés.
        </p>
        <p>
          Ces suggestions sont affichées dans une liste déroulante qui se met à
          jour au fur et à mesure de la frappe. Voici comment utiliser cette
          fonctionnalité étape par étape :
        </p>
        <ol>
          <li>
            <h3>Commencer la saisie :</h3>
            <p>
              Commencez à entrer l'adresse par le début, typiquement le numéro
              et la rue. À mesure que vous tapez, une liste de suggestions
              basées sur le texte entré s'affiche sous le champ.
            </p>
          </li>
          <li>
            <h3>Sélectionner une suggestion :</h3>
            <p>
              Si une suggestion correspond à votre adresse, cliquez dessus pour
              remplir le champ avec cette adresse. Vous pouvez également
              continuer à taper pour affiner les résultats.
            </p>
          </li>
          <li>
            <h3>Valider l'adresse :</h3>
            <p>
              Une fois que vous avez trouvé votre adresse dans la liste, cliquez
              dessus pour la sélectionner. Le champ sera rempli avec l'adresse
              complète, prête à être validée.
            </p>
          </li>
        </ol>
      </section>
    </>
  );
}
