import { PropsWithChildren, useEffect } from "react";
import { useAddressCoverage } from "./contexts/address-coverage-demo.context";

export default function Layout({ children }: PropsWithChildren) {
  const {
    fullTextAddress,
    setFullTextAddress,
    setExternalPlace,
    addressPlace,
  } = useAddressCoverage();

  useEffect(() => {
    (async () => {
      window.addEventListener("message", function (e) {
        try {
          if (e.data) {
            switch (e.data.type) {
              case "address":
                setFullTextAddress(e.data.address);
                break;
              case "google_address":
                setExternalPlace(e.data.address);
                break;
              default:
                break;
            }
          }
        } catch (e) {
          console.error("An issue occured:", e);
        }
      });
    })();
  }, [setExternalPlace, setFullTextAddress]);

  useEffect(() => {
    (async () => {
      if (fullTextAddress) {
        window.parent.postMessage(
          {
            type: "address",
            address: fullTextAddress,
          },
          "*"
        );
      }
    })();
  }, [fullTextAddress]);

  useEffect(() => {
    (async () => {
      try {
        if (addressPlace) {
          const payload = JSON.parse(JSON.stringify(addressPlace));
          if (payload !== undefined)
            window.parent.postMessage(
              {
                type: "google_address",
                details: {
                  address: payload,
                },
              },
              "*"
            );
        }
      } catch (e) {
        console.error("Issue triggering event: ", e);
      } finally {
      }
    })();
  }, [addressPlace]);

  return (
    <>
      <main id="main">{children}</main>
    </>
  );
}
