import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import coffeeMachineImg from "../../assets/images/cafetiere.png";
import drillImg from "../../assets/images/aspirateur.png";
import hairDryerImg from "../../assets/images/centrale.png";
import monitorImg from "../../assets/images/robot.png";
import config from "../../utils/config";

import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { appliancesSchema } from "./appliances-field";
import { QuantityInput } from "./quantity-input";

export const smallAppliancesSchema = z.object({
  smallAppliances: z.object({
    quantity: z
      .number({
        required_error:
          "Veuillez renseigner la quantité de petit électroménager",
        invalid_type_error: "Veuillez renseigner un nombre valide",
      })
      .nonnegative({ message: "Veuillez renseigner un nombre positif" })
      .refine(
        (data) =>
          config.IS_FEATURE_SMALL_APPLIANCES_ENABLED ? true : data === 0,
        "La fonctionnalité `Petit électroménager` n'est pas activée"
      ),
  }),
});

// SmallAppliances fields depend on Appliances
const formSchema = smallAppliancesSchema.and(appliancesSchema);

export function SmallAppliancesFormField() {
  const form = useFormContext<z.infer<typeof formSchema>>();
  const selectedAppliances = form.watch("appliances");

  useEffect(() => {
    if (selectedAppliances.length === 0) {
      form.setValue("smallAppliances", { quantity: 0 });
    }
  }, [selectedAppliances, form]);

  // SmallAppliances are only displayed if at least one appliance is selected
  if (selectedAppliances.length === 0) {
    return null;
  }

  return (
    <FormField
      control={form.control}
      name="smallAppliances.quantity"
      render={({ field }) => (
        <FormItem>
          <div className="flex flex-col space-y-4 rounded-md border border-input bg-background px-3 py-2 text-sm">
            <p className="font-medium">
              Donnez-nous vos petits appareils en plus !
            </p>
            <FormControl>
              <div className="flex flex-row gap-2 items-center w-full">
                <div className="flex-1 grid grid-cols-[repeat(auto-fill,minmax(3rem,_1fr))]">
                  <img
                    src={drillImg}
                    alt="perceuse"
                    className="max-w-12"
                    draggable={false}
                  />
                  <img
                    src={coffeeMachineImg}
                    alt="machine à café"
                    className="max-w-12"
                    draggable={false}
                  />
                  <img
                    src={hairDryerImg}
                    alt="sèche cheveux"
                    className="max-w-12"
                    draggable={false}
                  />
                  <img
                    src={monitorImg}
                    alt="ecran d'ordinateur"
                    className="max-w-12"
                    draggable={false}
                  />
                </div>
                <div className="ml-auto">
                  <QuantityInput
                    quantity={field.value}
                    onQuantityChange={field.onChange}
                    min={0}
                  />
                </div>
              </div>
            </FormControl>
            <p className="font-bold">Uniquement appareils sans batterie</p>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}
