const routes = {
  create: "/",
  temporarySummary: "/summary-t",
  summary: (token: string) => `/summary/${token}`,
  edit: "/edit",
  editPicking: "/edit/picking",
  editContact: "/edit/contact",
  editAppliances: "/edit/appliances",
  cancelConfirmed: "/cancel-confirmed",
  addressRequested: "/address-requested",
};

export default routes;
